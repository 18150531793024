<template>
  <div>
    <primaryButton
      v-if="functionElement"
      class="mr-2"
      :attributesArray="functionElement['attributesArray']"
      type="button"
      :value="functionElement['functionName']"
       v-on="$listeners"
      :label="functionElement['functionName']"
      color="save"
      :disabled="!isValid || loading ||disabled"
      :loading="loading && isValid"
      :icon="'mdi-folder-move'"
    ></primaryButton>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";

export default {
  props: ["isValid", "loading", "functionElement", "result"],
  components: {
    primaryButton,
  },
  computed: {
    disabled() {
      let disabled = true;

      if (this.result.valueLine.projects) {
        disabled = false;
      }
      return disabled;
    },
  },
};
</script>